import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="New release updates | GlassHive"
                description="At GlassHive, we are continuously making improvements to the platform to enhance user experience and functionality. Check out the newest features!"
            />
            <div className="release-notes-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text">
                                GlassHive new release updates
                            </h1>
                            <p className="hero-subtext white-text">
                                August 2022
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/iLQOPt0bRlg"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                The latest updates with GlassHive
                            </h4>
                            <p>
                                <b>Welcome back</b> to another Glasshive release
                                notes video. Here we’ll discuss all the updates,
                                features, or quality of life improvements we
                                have made in the Hive. Let's get into it!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">New features</h4>
                            <ul>
                                <li>
                                    Partner users can now control the email
                                    notifications they receive
                                </li>
                                <li>
                                    Created a new pop up menu when you click on
                                    your profile image
                                </li>
                                <li>
                                    Removed notifications feature while bug
                                    fixes are addressed on backend
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Updates</h4>
                            <ul>
                                <li>Tables now have updated header UI</li>
                                <li>
                                    Removed lifetime and added 7 day data to
                                    email analytics page
                                </li>
                                <li>
                                    Collateral Builder color picker now closes
                                    when a color is selected
                                </li>
                                <li>
                                    Collateral Builder designer color picker
                                    button now opens and closes when clicked on
                                </li>
                                <li>
                                    Users can now create more customized
                                    opportunities
                                </li>
                                <li>
                                    Users now have the ability to clear out
                                    company and contact picture
                                </li>
                                <li>
                                    Users can now add team quotas for gross
                                    margin and MRR
                                </li>
                                <li>
                                    Sales dashboards and reports updated to
                                    reflect new quotas for salespeople
                                </li>
                            </ul>

                            <h4 className="centered-mobile">Bug fixes</h4>
                            <ul>
                                <li>
                                    Fixed bug where campaign content wouldn't
                                    get updated when updating during the review
                                    process
                                </li>
                                <li>
                                    Fixed bug when adding a new contact with an
                                    email with special characters
                                </li>
                                <li>
                                    Fixed bug where LP Builder font didn't match
                                    what was published
                                </li>
                                <li>
                                    Campaign analytics "First 24 Hours" now
                                    properly displays analytics when there are
                                    no opens/clicks
                                </li>
                                <li>
                                    Fixed 2FA code verification mobile page
                                    format
                                </li>
                                <li>
                                    Fixed bug where you couldn't edit an
                                    opportunity that has a deal line item with a
                                    category
                                </li>

                                <li>
                                    Fixed bug where agency reports weren't
                                    reporting on Marketing Plan campaigns
                                </li>

                                <li>
                                    Fixed bug where users couldn't sort LP
                                    Templates by Vendor
                                </li>

                                <li>
                                    Fixed bug where vendors couldn't sort their
                                    partners by Account Created
                                </li>
                            </ul>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free account today!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
