import React, { useState } from 'react';
import ShinyButton from '../ShinyButton';
import { throwSignupConversionEvent } from '../../scripts/utils';

import './style.scss';

const FreeTrialSignup = ({
    titleText = 'Sign up for a free GlassHive account',
}) => {
    const [email, setEmail] = useState('');

    return (
        <div className="form-container">
            <h3>{titleText}</h3>
            <input
                type="email"
                required
                name="email"
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
            />
            <ShinyButton
                className="btn blue create-free-account-button"
                onClick={() =>
                    throwSignupConversionEvent(
                        'https://app.glasshive.com/Account/Login?s=signup&email=' +
                            email
                    )
                }
            >
                Create an account
            </ShinyButton>
            <p className="login-statement">
                Already signed up?{' '}
                <a href="https://app.glasshive.com/Account/Login">Log In</a>
            </p>
        </div>
    );
};

export default FreeTrialSignup;
